import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/agent/home-data`);
  },

  async addStudent(payload) {
    return axiosInstance().post(`/agent/add-student`, payload);
  },

  async saveStudentProfile(payload) {
    const { student_user_id } = payload;

    return axiosInstance().post(`/agent/save-student-profile/${student_user_id}`, payload);
  },

  async removeStudentDocument(payload) {
    const { student_user_id, sd_mapper_id } = payload;

    return axiosInstance().post(`/agent/remove-student-doc/${student_user_id}/${sd_mapper_id}`);
  },

  async removeStudentVisaDocument(payload) {
    const { student_user_id, svd_id } = payload;

    return axiosInstance().post(`/agent/remove-student-visa-doc/${student_user_id}/${svd_id}`);
  },

  async applyForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(`/agent/apply-course-student/${student_user_id}/${course_id}`, payload);
  },

  async cancelApplicationForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(`/agent/cancel-application-student/${student_user_id}/${course_id}`);
  },

  async acceptSuggestionForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(`/agent/accept-suggestion/${student_user_id}/${course_id}`);
  },

  async rejectSuggestionForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(`/agent/reject-suggestion/${student_user_id}/${course_id}`);
  },

  async getAllStudentSupportList(params) {
    return axiosInstance().get(`/agent/student-support`, { params });
  },

  async getAllStudentsForStudentSupport(params) {
    return axiosInstance().get(`/agent/student-support-students`, { params });
  },

  async getSingleStudentSupport(user_id) {
    return axiosInstance().get(`/agent/student-support/${user_id}`);
  },

  async saveStudentSupport(payload) {
    return axiosInstance().post(`/agent/student-support`, payload);
  },

  async getAreaFranchises(params) {
    return axiosInstance().get(`/agent/area-franchises`, { params });
  },

  async getMicroFranchises(params) {
    return axiosInstance().get(`/agent/micro-franchises`, { params });
  },

  async commissionSwitchAuth(payload) {
    return axiosInstance().post(`/agent/commission-switch-auth`, payload);
  },

  async getFranchiseIndustryNews() {
    return await axiosInstance().get(`/agent/industry-news`);
  },

  async getFranchiseMaterial() {
    return await axiosInstance().get(`/agent/material`);
  },

  async getQuizResult() {
    return await axiosInstance().get(`/agent/training/quiz-result`);
  },

  async getQuizResponses() {
    return await axiosInstance().get(`/agent/quiz-responses`);
  },

  async getAllProducts(params) {
    return await axiosInstance().get(`/agent/shop-products`, {
      params,
    });
  },

  async getSingleProduct(product_id) {
    return await axiosInstance().get(`/agent/shop-products/${product_id}`);
  },

  async getAllOrders(params) {
    return await axiosInstance().get(`/agent/shop-orders`, {
      params,
    });
  },

  async placeShopOrder(payload) {
    return await axiosInstance().post(`/agent/place-shop-order`, payload);
  },

  async makeShopPayUPayment(payload) {
    return await axiosInstance().post(`/agent/shop/payu-payment/make`, payload);
  },

  async verifyShopPayUPayment(transaction_id) {
    return await axiosInstance().get(`/agent/shop/payu-payment/verify/${transaction_id}`);
  },

  async makeShopStripePayment(payload) {
    return await axiosInstance().post(`/agent/shop/stripe-payment/make`, payload);
  },

  async verifyShopStripePayment(transaction_id) {
    return await axiosInstance().get(`/agent/shop/stripe-payment/verify/${transaction_id}`);
  },
};

<template>
  <div class="row">
    <div class="col-12">
      <div class="course-provider" :style="{ height: showAllCourses ? '600px' : 'auto' }" @click="() =>
        $router.push({
          path: `/university-detail/${universityDetails.university_id}`,
        })
        ">
        <div class="row mb-1 align-items-center">
          <div class="col-2">
            <figure>
              <img :src="university_logo" alt="" class="w-100" />
            </figure>
          </div>
          <div class="col-10">
            <div class="mb-1 d-flex align-items-center justify-content-between flex-wrap">
              <span class="course-provider__name">{{
                universityDetails.university_name
                }}</span>
              <div class="d-flex align-items-center">
                <span v-if="
                  item.rating_stars && !isNaN(parseInt(universityDetails.rating_stars))
                " class="course-provider__point mr-3">
                  <img v-for="(item, index) in parseInt(universityDetails.rating_stars)" :key="'star_' + index"
                    src="@/assets/images/svg/star-yellow.svg" alt="" />
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.country_name }}, {{ universityDetails.state_name }}
              </span>
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.city_name }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="universityDetails.courses && universityDetails.courses.length > 0">
          <div v-for="i in showAllCourses
            ? universityDetails.courses
            : universityDetails.courses.slice(0, 2)" :key="'course_id_' + i.course_id"
            @click.stop.prevent="gotToCourseDetails(i)">
            <Course :course="i" @apply-course-for-student="(e) => {
              $emit('apply-course-for-student', e);
            }
              " @recommend-course="(e) => {
                $emit('recommend-course', e);
              }
                " @apply-course="(e) => {
                  $emit('apply-course', e);
                }
                  " @reload-list="(e) => {
                    $emit('reload-list');
                  }
                    " />
          </div>
        </div>
        <div v-if="universityDetails.course_ids && universityDetails.course_ids.split(',').length > 2"
          class="text-center course-provider__bottom" :style="{ bottom: showAllCourses ? '1.3rem' : 'auto' }">
          <!-- <b-button
            @click.stop.prevent="
              () =>
                $router.push({
                  path: `/university-detail/${item.university_id}`,
                })
            "
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
          >
            VIEW {{ item.course_ids.split(",").length }} MORE COURSES
          </b-button> -->
          <b-button @click.stop.prevent="toggleCourses" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary"
            size="sm">
            {{
              showAllCourses
                ? "SHOW LESS COURSES"
                : `VIEW ${universityDetails.course_ids.split(",").length - 2} MORE COURSES`
            }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FILESURL } from "@/config";
import Course from "./Course";
import {
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";

export default {
  name: "CourseProvider",
  components: {
    Course,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
  },
  directives: {
    Ripple,
  },

  props: {
    item: {
      type: Object,
      default: {},
    },
    universityDetail: {
      type: Boolean,
      default: true,
    },
    collapseCourse: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      defaulf: {}
    }
  },
  data() {
    return {
      FILESURL,
      showAllCourses: false,
      universityDetails: this.item,
      // university_logo: "@/assets/images/svg/stanford.svg"
    };
  },
  computed: {
    university_logo() {
      if (this.item.university_logo) {
        return FILESURL + this.item.university_logo;
      } else {
        return "../../../../../assets/images/svg/stanford.svg";
      }
    },
  },
  methods: {
    gotToCourseDetails(course) {
      this.$router.push({
        path: `/university-detail/${course.university_id}?course_id=${course.course_id}`,
      });
    },

    async getAllCoursesByUniversityId() {
      const res = await CommonServices.getCoursesByUniversityId(this.universityDetails.university_id, this.filters)
      console.log({ courses: res.data.data });
      this.universityDetails.courses = res.data.data
    },

    toggleCourses() {
      this.showAllCourses = !this.showAllCourses;
      if (this.showAllCourses) {
        this.getAllCoursesByUniversityId()
      }
    },
  },
};
</script>
<style>
.course-provider {
  overflow-y: scroll;
}
</style>

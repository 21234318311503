var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"course-provider",style:({ height: _vm.showAllCourses ? '600px' : 'auto' }),on:{"click":function () { return _vm.$router.push({
        path: ("/university-detail/" + (_vm.universityDetails.university_id)),
      }); }}},[_c('div',{staticClass:"row mb-1 align-items-center"},[_c('div',{staticClass:"col-2"},[_c('figure',[_c('img',{staticClass:"w-100",attrs:{"src":_vm.university_logo,"alt":""}})])]),_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"mb-1 d-flex align-items-center justify-content-between flex-wrap"},[_c('span',{staticClass:"course-provider__name"},[_vm._v(_vm._s(_vm.universityDetails.university_name))]),_c('div',{staticClass:"d-flex align-items-center"},[(
                _vm.item.rating_stars && !isNaN(parseInt(_vm.universityDetails.rating_stars))
              )?_c('span',{staticClass:"course-provider__point mr-3"},_vm._l((parseInt(_vm.universityDetails.rating_stars)),function(item,index){return _c('img',{key:'star_' + index,attrs:{"src":require("@/assets/images/svg/star-yellow.svg"),"alt":""}})}),0):_vm._e()])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"course-provider__point"},[_c('img',{attrs:{"src":require("@/assets/images/svg/location.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.universityDetails.country_name)+", "+_vm._s(_vm.universityDetails.state_name)+" ")]),_c('span',{staticClass:"course-provider__point"},[_c('img',{attrs:{"src":require("@/assets/images/svg/location.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.universityDetails.city_name)+" ")])])])]),(_vm.universityDetails.courses && _vm.universityDetails.courses.length > 0)?_c('div',_vm._l((_vm.showAllCourses
          ? _vm.universityDetails.courses
          : _vm.universityDetails.courses.slice(0, 2)),function(i){return _c('div',{key:'course_id_' + i.course_id,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotToCourseDetails(i)}}},[_c('Course',{attrs:{"course":i},on:{"apply-course-for-student":function (e) {
            _vm.$emit('apply-course-for-student', e);
          },"recommend-course":function (e) {
              _vm.$emit('recommend-course', e);
            },"apply-course":function (e) {
                _vm.$emit('apply-course', e);
              },"reload-list":function (e) {
                  _vm.$emit('reload-list');
                }}})],1)}),0):_vm._e(),(_vm.universityDetails.course_ids && _vm.universityDetails.course_ids.split(',').length > 2)?_c('div',{staticClass:"text-center course-provider__bottom",style:({ bottom: _vm.showAllCourses ? '1.3rem' : 'auto' })},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleCourses.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.showAllCourses ? "SHOW LESS COURSES" : ("VIEW " + (_vm.universityDetails.course_ids.split(",").length - 2) + " MORE COURSES"))+" ")])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="col-12 px-0">
    <div class="course" v-if="!courseDetail">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div class="col-8">
          <h6 class="course__title mb-0">
            {{ course.course_name }}
          </h6>
        </div>
        <div v-if="user_type === 'student'" :class="courseCollapse ? 'mr-4' : ''">
          <div v-if="course.is_favourite && course.is_favourite === 'Y'" class="remove-bookmark"
            @click.stop.prevent="removeCourseFromFavourite(course.course_id)">
            <feather-icon size="24" icon="BookmarkIcon" />
          </div>
          <div v-else class="add-bookmark" @click.stop.prevent="addCourseToFavourite(course.course_id)">
            <feather-icon size="24" icon="BookmarkIcon" />
          </div>
        </div>
        <div v-if="['counselor', 'agent', 'franchise', 'student_support'].includes(user_type)"
          :class="courseCollapse ? 'mr-3' : ''">
          <div>
            <b-button size="sm" @click.stop.prevent="() => {
              $emit('recommend-course', course);
            }
              " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="">
              Recommend
            </b-button>
          </div>
        </div>
        <div v-if="['agent', 'franchise', 'student_support'].includes(user_type)" :class="courseCollapse ? 'mr-3' : ''">
          <div>
            <b-button size="sm" @click.stop.prevent="() => {
              $emit('apply-course-for-student', course);
            }
              " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="">
              Apply For Student
            </b-button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <div class="course-point">
          <img src="@/assets/images/svg/cap.svg" alt="" />
          <div class="">
            <p>Discipline</p>
            <p>{{ course.course_category_name }}</p>
          </div>
        </div>
        <div class="course-point">
          <img src="@/assets/images/svg/star-green.svg" alt="" />
          <div class="">
            <p>Level</p>
            <p>{{ course.course_level_name }}</p>
          </div>
        </div>
        <div class="course-point">
          <img src="@/assets/images/svg/money.svg" alt="" />
          <div class="">
            <p>
              Fee
              <!-- <sub class="sm-size">(P.A.)</sub> -->
              (P.A.)
            </p>
            <p v-if="course.course_fee_pa">
              {{ course.currency }}
              {{ course.course_fee_pa.toLocaleString("en-IN") }}
            </p>
            <p v-else-if="course.course_fee">
              INR {{ course.course_fee.toLocaleString("en-IN") }}
            </p>
          </div>
        </div>
        <div class="course-point">
          <img src="@/assets/images/svg/time.svg" alt="" />
          <div class="duration-container">
            <p>Duration</p>
            <div class="duration-item">
              {{ getCourseDuration(course) }} &nbsp;
            </div>
          </div>
        </div>
        <div class="course-point">
          <img src="@/assets/images/svg/time.svg" alt="" />
          <div class="">
            <p>Intakes</p>
            <p>
              {{ formatIntake(course.intake) }}
            </p>
          </div>
        </div>
        <div v-if="
          ['agent', 'franchise', 'student_support'].includes(user_type) &&
          $store.getters['user/getUserDetails'].show_commission === 'Y' &&
          course.commission
        " class="course-point">
          <img src="@/assets/images/svg/money.svg" alt="" />
          <div class="">
            <p>
              Commission
              <!-- <sub class="sm-size">(P.A.)</sub> -->
            </p>
            <div class="flex-commission">
              <p>
                {{ getCommission(course) }}
              </p>
              <div v-b-tooltip.hover title="This is approximate value the final rate may vary" class="info-tag">
                <span>i</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="user_type === 'student'" class="text-right mt-1 mt-lg-0">
          <b-button v-if="course.is_applied && course.is_applied === 'Y'" class="px-2" variant="success" size="sm"
            :disabled="true">APPLIED</b-button>
          <b-button class="px-2" variant="warning" size="sm" v-else @click.stop.prevent="(e) => {
            $emit('apply-course', course);
          }
            ">
            APPLY
          </b-button>
        </div>
      </div>
    </div>

    <div class="course-detail" v-if="courseDetail">
      <div v-if="course.application_fee">
        <div class="course-detail__sm-title">Application Fees</div>
        <div class="course-detail__text"></div>
      </div>
      <div v-if="course.course_benefits">
        <div class="course-detail__sm-title">Course Benefits</div>
        <div class="course-detail__text" v-html="course.course_benefits"></div>
      </div>
      <div v-if="course.general_eligibility">
        <div class="course-detail__sm-title">General Eligibility</div>
        <div class="course-detail__text">
          {{ course.general_eligibility }}
        </div>
      </div>
      <div v-if="course.languages_requirement">
        <div class="course-detail__sm-title">Language Requirement</div>
        <div class="course-detail__text">
          {{ course.languages_requirement }}
        </div>
      </div>
      <div v-if="course.intake">
        <div class="course-detail__sm-title">Intake</div>
        <div>
          <span>Intake Start: </span>
          <span>{{ course.intake }}</span>
        </div>
        <div v-if="course.intake_start">
          <span>Intake Start:</span>
          <span>{{ course.intake_start }}</span>
        </div>
        <div v-if="course.intake_end">
          <span>Intake End:</span>
          <span>{{ course.intake_end }}</span>
        </div>
        <div v-if="course.intake_deadline_1">
          <span>Intake Deadline 1:</span>
          <span>{{ course.intake_deadline_1 }}</span>
        </div>
        <div v-if="course.intake_deadline_2">
          <span>Intake Deadline 2:</span>
          <span>{{ course.intake_deadline_2 }}</span>
        </div>
        <div v-if="course.intake_deadline_3">
          <span>Intake Deadline 3:</span>
          <span>{{ course.intake_deadline_3 }}</span>
        </div>
        <div v-if="course.intake_deadline_4">
          <span>Intake Deadline 4:</span>
          <span>{{ course.intake_deadline_4 }}</span>
        </div>
        <div v-if="course.intake_deadline_5">
          <span>Intake Deadline 5:</span>
          <span>{{ course.intake_deadline_5 }}</span>
        </div>
        <div v-if="course.intake_deadline_6">
          <span>Intake Deadline 6:</span>
          <span>{{ course.intake_deadline_6 }}</span>
        </div>
      </div>
      <div>
        <div class="course-detail__sm-title">Additional Information</div>
        <div class="course-detail__text">
          <a :href="course.additional_information_link">{{
            course.additional_information_link
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BBadge, VBTooltip } from "bootstrap-vue";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import helpers from "@/libs/helpers";

export default {
  name: "Course",
  components: {
    BButton,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    course: {
      type: Object,
      default: {},
    },
    courseDetail: {
      type: Boolean,
      default: false,
    },
    courseCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupData: null,
      selectedStudents: [],
      studentsList: [],
      showTooltip: false,
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
    commission_percent() {
      if (store.getters["user/getUserDetails"].commission_percent) {
        return store.getters["user/getUserDetails"].commission_percent;
      } else {
        return null;
      }
    },
  },
  methods: {
    getCourseDuration(course) {
      try {
        if (course.course_year && course.course_month) {
          const y = parseInt(course.course_year);
          const m = parseInt(course.course_month);
          return `${y * 12 + m} Months`;
        } else if (course.course_year) {
          return `${course.course_year} Years`;
        } else if (course.course_month) {
          return `${course.course_month} Months`;
        }
      } catch (err) {
        console.error(err);
        return "";
      }
    },
    formatIntake: helpers.formatIntake,
    displayTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    getCommission(course) {
      if (course.agent_commission_pa) {
        return `${course.currency} ${course.agent_commission_pa.toLocaleString(
          "en-IN"
        )}`;
      } else if (course.agent_commission) {
        return `INR ${course.agent_commission.toLocaleString("en-IN")}`;
      }
      return "";
    },

    addCourseToFavourite(course_id) {
      StudentServices.addCourseToFavourite({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.$emit("reload-list");
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course added to favourite",
                icon: "PlusCircleIcon",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course not added to favourite",
                icon: "XIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error adding course to favourite ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error adding course to favourite",
              icon: "XIcon",
              variant: "failure",
            },
          });
        });
    },
    removeCourseFromFavourite(course_id) {
      StudentServices.removeCourseFromFavourite({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.$emit("reload-list");
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course removed from favourite",
                icon: "MinusCircleIcon",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course not removed from favourite",
                icon: "XIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error adding course to favourite ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error removing course from favourite",
              icon: "XIcon",
              variant: "failure",
            },
          });
        });
    },
    resetSelectedStudents() {
      this.selectedStudents = [];
    },
  },
};
</script>

<style>
.course__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.course__title:hover {
  white-space: normal;
  text-overflow: clip;
}

.course-point {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  margin-right: 20px;
}

.course-detail>div {
  margin-bottom: 10px;
}

.course-detail__sm-title {
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

.flex-commission {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-tag {
  color: var(--primary-1);
  font-weight: bold;
  border: 1px solid var(--primary-1);
  border-radius: 50%;
  padding: 0px 06px;
}

.tooltip-inner {
  color: #fff;
  background: #ff9900 !important;
}

.duration-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* Center items horizontally */
}

/* Style the individual duration items with fixed spacing */
.duration-item {
  /* margin: 10px 0; */
  text-align: left;
}
</style>
